import { Component, default as React } from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

export const AvoidScamsPage = () => {
  return (
    <>
      <Header isDark={false} />

      <div className="faq-all">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="title faq-all__title">HOW TO Avoid Fraud</h2>
            </div>
          </div>

          <div className="row faq__wrapper pb-4">
            <div className="col-lg-12">
              <div className="box faq-box">
                <p>
                  Gempal.exchange would like to share some information on various safety measurements to protect yourself and your finances.
                  We urge you to be careful as to how you use your virtual currency and read our guide on the most common crypto deceptions
                  and means of avoiding them.
                </p>
                <p>
                  Never disclose your account details or give access to your Gempal account, or any financial account for that matter, to
                  any third party.{" "}
                  <strong>
                    Your personal account is for your personal use only, and not for the use or access by any third party. In any event, you
                    are fully responsible for all acts or omissions of any third party accessing and/or using your account.
                  </strong>
                </p>
                <p>
                  Gempal customer service representatives will never contact you from unauthorized email addresses or phone numbers. Please,
                  refer to the Company’s official contact details at <a href="/contact_us">https://gempal.exchange/contact_us</a>{" "}
                </p>
                <p>Please, be aware of the following, in case you decided to transfer your cryptocoins to an external wallet:</p>
                <p>
                  <strong>When cryptocurrency is sent to an external platform, the transaction is irrevocable and non-refundable.</strong>{" "}
                  No changes can be made as soon as the transaction is completed as Gempal will no longer have any access to the external
                  wallet or be in any way able to trace it.
                </p>
                <p>
                  In addition to this, by sending your coins to your personal external wallet Gempal performs services in full. That means
                  that you lose the ability to claim from us any type of refund or create any type of a chargeback or a recall for the funds
                  you used to buy the coins.
                </p>
                <p>
                  One more important thing to mention is that virtual currencies are decentralized and therefore transactions with them are
                  untraceable. So, if your coins get lost or stolen, it will be extremely difficult to find or recover them.{" "}
                  <strong>Please, be careful with your coins.</strong>{" "}
                </p>
                <p>
                  As it has been mentioned above, there are some deceptive crypto activities due to the fact that crypto coin transactions
                  are untraceable. Impostures convince people to purchase coins and transfer them to their wallets with a final aim to
                  mislead the client and get a hold of his funds. <br />
                  Please, beware of common crypto deceptions and be very careful with how you use your coins.
                </p>
                <p>
                  In case you have spotted any fraudulent activities at Gempal.exchange or become a victim of a such activities, please
                  report to <a href="mailto:support@gempal.exchange;">support@gempal.exchange</a> .
                </p>
                <p>Feel free to contact us in case you have any questions.</p>
                <p>
                  Gempal Network Exchange <br /> Cryptocurrency Exchange Platform
                </p>
              </div>
            </div>
          </div>

          <div className="row faq__wrapper faq__wrapper_avoid">
            <div className="col-lg-12">
              <div className="box faq-box">
                <h2 className="faq-article__title">Types of crypto scams </h2>
                <p>
                  Cryptocurrencies are complicated and confusing to new users which makes them an ideal target for scammers. But if you read
                  this guide on the most commonly observed scams you will be able to protect yourself and your finances..
                </p>
                <h3 className="faq__caption">1. Blackmail</h3>
                <p>
                  Blackmail is when strangers threaten you in exchange for bitcoin as a means of extortion. One common execution of this
                  method is by email, where-in the sender transmits a message claiming that they have hacked into your computer and is
                  operating it via remote desktop protocol. The sender says that a key logger has been installed and that your web cam was
                  used to record you doing something you may not want others to know about. The sender provides two options - send bitcoin
                  to suppress the material, or send nothing and see the content sent to your email contacts and spread across your social
                  networks. Scammers use stolen email lists and other leaked user information to run this scheme across thousands of people.
                </p>
                <h3 className="faq__caption faq__caption_underline">How to avoid: </h3>
                <ul className="list faq__list">
                  <li>If you or someone you know gets a letter like this, report it immediately to your local police.</li>
                  <li>Threats, intimidation and high-pressure tactics are classic signs of a scam. Lea</li>
                </ul>
                <h3 className="faq__caption">2. Fake Exchanges and wallets</h3>
                <p>
                  As bitcoin has become more popular, more people want to acquire it. Unfortunately, some people have taken advantage of
                  this and have been known to set up fake bitcoin exchanges. These fake exchanges may trick users by offering extremely
                  competitive market prices, with quick and easy access to some cheap bitcoin. Be sure to use a reputable exchange when
                  buying or selling bitcoin.
                </p>
                <p>
                  Other scammers have turned their attention to creating quite sophisticated fake wallet apps that, once downloaded to a
                  user’s smartphone, can be used to steal critical account details. These apps have even made it into official, legitimate
                  app stores like Google Play, so it pays to do your research before downloading anything to your phone.
                </p>
                <h3 className="faq__caption faq__caption_underline">How to avoid: </h3>
                <ul className="list faq__list">
                  <li>
                    Stick with well-known and popular exchanges always the better choice will be a regulated exchange such as gempal.exchange.
                  </li>
                  <li>
                    Thoroughly research any exchange or wallet before creating an account. Where is the company registered? Are there
                    reliable reviews from other users confirming its legitimacy?
                  </li>
                  <li>Don’t let yourself be pressured into depositing funds or providing any personal information.</li>
                  <li>
                    Don’t just randomly pick a wallet from the app store — only download apps and software from legitimate wallet providers
                    and exchanges.
                  </li>
                </ul>
                <h3 className="faq__caption">3. Free Giveaways</h3>
                <p>
                  Due to the viral nature of how information spreads across on the internet, scammers seek to take advantage of people by
                  offering free giveaways of bitcoin or other digital currencies in exchange for sending a small amount to register, or by
                  providing some personal information.
                </p>
                <h3 className="faq__caption faq__caption_underline">How to avoid: </h3>
                <p>
                  When you see this on a website or social network, it's best to immediately report the content as fraudulent, so that
                  others don't fall victim.
                </p>
                <h3 className="faq__caption">4. Old-school scams</h3>
                <p>
                  Cryptos may be based on new technology, but there are still plenty of scammers using old tricks. The classic example of
                  this is an unsolicited phone call or email from someone claiming to be with your tax authority or bank. This fictional tax
                  man/bank officer will try to convince you that you owe them money and you’ll be facing legal action if you don’t transfer
                  them a certain amount of bitcoin as soon as possible.
                </p>
                <p>
                  The tried-and-tested “Nigerian prince” scam has also migrated into the world of cryptocurrency. So if you’re ever
                  contacted by someone overseas promising you a share in a large sum of digital currency if you help them transfer funds out
                  of their own country, use your common sense and recognize it for the scam it is.
                </p>
                <p>
                  Or the unsolicited call/email from a company or person with which you may or may not had dealings in the past claiming
                  that they owe you money and/or that they hold money belonging to you and want to reimburse them back to you, but there are
                  fees to be paid first and/or some other action such as establishing a link and/or connection between accounts. If any
                  Company or person legitimately owes you money, they can send the money in FIAT currency (USD/EUR) directly to your
                  preferred bank account, with no intermediary steps needed to be taken by you.
                </p>
                <h3 className="faq__caption faq__caption_underline">How to avoid: </h3>
                <ul className="list faq__list">
                  <li>Use your common sense.</li>
                  <li>Don’t trust unsolicited emails or phone calls.</li>
                </ul>
                <h3 className="faq__caption">5. Impersonation</h3>
                <p>
                  Unfortunately it's very easy for scammers to create social media accounts and impersonate people. Often times they wait,
                  until the person they're trying to impersonate publishes content. The impersonator then replies to it with a follow-up
                  message or call to action - like a free giveaway - using an account that looks almost identical to the original poster or
                  author. This makes it seem like the original person is saying it. Alternatively, impersonators may also try to use these
                  same fake accounts to trick others via private or direct message into taking some kind of action in an attempt to defraud
                  or compromise.{" "}
                </p>
                <h3 className="faq__caption faq__caption_underline">How to avoid: </h3>
                <ul className="list faq__list">
                  <li>
                    Never participate in free giveaways, and if you receive an odd request via someone in your network, it's best to double
                    check to confirm the authenticity via multiple mediums of communication..
                  </li>
                </ul>
                <h3 className="faq__caption">6. Malware</h3>
                <p>
                  Hackers have become very creative at finding ways to steal from people. When sending bitcoin, always be sure to double or
                  triple check the address you're sending to. Some malware programs, once installed, will change bitcoin addresses when
                  they're pasted from a user's clipboard, so that all of the bitcoin unknowingly gets sent to the hacker's address instead.
                  Since there is little chance of reversing a bitcoin transaction once it's confirmed by the network, noticing this after
                  the fact means it's too late and most likely can't be recovered.{" "}
                </p>
                <h3 className="faq__caption faq__caption_underline">How to avoid: </h3>
                <ul className="list faq__list">
                  <li>It's a good idea to be cautious about what programs you allow to have administrator access on your devices.</li>
                  <li>An up-to-date, reputable virus scanner can also help.</li>
                </ul>
                <h3 className="faq__caption">7. Meet in Person</h3>
                <p>
                  When buying or selling bitcoin locally, a counterparty may ask you to meet in person to conduct the exchange. If it isn't
                  a trusted party that you already know, this is a very risky proposition that could result in you getting robbed or
                  injured. Con-artists have also been known to exchange counterfeit fiat currency in exchange for bitcoin
                </p>
                <h3 className="faq__caption faq__caption_underline">How to avoid: </h3>
                <ul className="list faq__list">
                  <li>Consider using a peer-to-peer platform to escrow the funds in place of meeting in person.</li>
                </ul>
                <h3 className="faq__caption">8. Phishing Emails</h3>
                <p>
                  Beware of emails purported to be from services you use soliciting you for action, such as resetting your password, or
                  clicking through to provide some sort of interaction with regard to your account. It can be very difficult to spot the
                  difference in a fake email that's trying to entice you to compromise your account, and a legitimate one sent on behalf of
                  a product or service that you use.
                </p>
                <h3 className="faq__caption faq__caption_underline">How to avoid: </h3>
                <ul className="list faq__list">
                  <li>
                    When in doubt, considering triple-checking the authenticity of the communication by forwarding it to the company, using
                    the contact email address on their website, calling them on the telephone, and/or reaching out to them via their
                    official social media accounts.
                  </li>
                </ul>
                <h3 className="faq__caption">9. Phishing Websites</h3>
                <p>
                  Phishing websites often go hand-in-hand with phishing emails. Phishing emails can link to a replica website designed to
                  steal login credentials or prompt one to install malware. Phishing websites may also appear as sponsored results on search
                  engines or in app marketplaces used by mobile devices. Be wary that you aren't downloading a fake app or clicking a
                  sponsored link to a fake website.
                </p>
                <h3 className="faq__caption faq__caption_underline">How to avoid: </h3>
                <ul className="list faq__list">
                  <li>Do not install software or log in to a website unless you are 100% sure it isn't a fake one.</li>
                </ul>
                <h3 className="faq__caption">10. Ponzi Schemes</h3>
                <p>
                  Do not participate in offerings where one or more people offer you a guaranteed return in exchange for an upfront deposit.
                  This is known as a ponzi scheme, where-in future depositors' principals are used to pay previous investors. The end result
                  is usually a lot of people losing a lot of money.
                </p>
                <h3 className="faq__caption faq__caption_underline">How to avoid: </h3>
                <ul className="list faq__list">
                  <li>Look out for cryptocurrency projects that encourage you to recruit new investors to enjoy bigger profits</li>
                  <li>Never trust a scheme that promises returns that sound too good to be true.</li>
                </ul>
                <h3 className="faq__caption">11. Pyramid Schemes</h3>
                <p>
                  A pyramid scheme promises returns to participants based on the number of people they invite to join. This enables the
                  scheme to grow virally and rapidly, however, it most often doesn't result in any kind of meaningful return for the members
                  and/or those invited who also joined.{" "}
                </p>
                <h3 className="faq__caption faq__caption_underline">How to avoid: </h3>
                <ul className="list faq__list">
                  <li>
                    Never invite your personal network under the sole goal of accumulating rewards or returns from a product or service, and
                    do not contribute your own capital at the behest of others to accelerate the process.
                  </li>
                </ul>
                <h3 className="faq__caption">12. Prize Giveaways</h3>
                <p>
                  Similarly to free giveaways, prize giveaway scams trick people into taking action or supplying information about
                  themselves. For example, supplying a name, address, email and phone number in order to claim a prize. This can allow a
                  hacker to attempt to use the information to gain access to accounts by impersonating you.
                </p>
                <h3 className="faq__caption faq__caption_underline">How to avoid: </h3>
                <ul className="list faq__list">
                  <li>
                    Never trust any prize giveaways. Always check if the company offering such prices is legitimate and has a good
                    reputation.
                  </li>
                </ul>
                <h3 className="faq__caption">13. Pump and Dumps</h3>
                <p>
                  Do not trust people who entice you or others to invest because they claim that they know what the bitcoin price is going
                  to be. In a pump and dump scheme, a person (or persons) try to artificially drive up or pump the price so that they can
                  dump their holdings for a profit.
                </p>
                <h3 className="faq__caption faq__caption_underline">How to avoid: </h3>
                <ul>
                  <li>
                    Be wary of low-market-cap cryptos that normally have a low trading volume but that suddenly experience a sharp price
                    rise.
                  </li>
                  <li>Keep an eye out for “fake news” on social media that hypes particular coins.</li>
                  <li>Carefully research the credentials of any cryptocurrency before buying.</li>
                </ul>
                <h3 className="faq__caption">14. Ransomware</h3>
                <p>
                  This is a type of malware that partially or completely blocks access to a device unless you pay a ransom in bitcoin. It's
                  best to consult the advice of a trusted computer professional for removal assistance, rather than paying the ransom.{" "}
                </p>
                <h3 className="faq__caption faq__caption_underline">How to avoid: </h3>
                <ul>
                  <li>
                    Be careful about what programs you install on your devices, especially those that request administrator access. Also be
                    sure to double-check that the application you are downloading isn't a fake one that's impersonating a legitimate one
                    you've used in the past.
                  </li>
                </ul>
                <h3 className="faq__caption">15. Scam Coins</h3>
                <p>
                  Be careful when investing in alternative coins (altcoins). Amongst altcoins there may be scam coins, enticing users to
                  invest via private sales, or with presale discounts. Scam coins may feature a flashy website and/or boast a large
                  community to create a fear of missing out effect on people who discover it. This helps early holders pump up the price so
                  that they can dump and exit their positions for a profit. Scam coins without large communities may do airdrops - offering
                  free coins (or tokens) to people in exchange for joining their communities. This enables scam coins to present their
                  initiatives with inflated traction metrics to make investors feel like they're missing out when it comes time for them to
                  decide if they'd like to buy-in. Scam coins may also use the word Bitcoin in them in an effort to trick or mislead people
                  into thinking there is a legitimate relationship.
                </p>
                <h3 className="faq__caption faq__caption_underline">How to avoid: </h3>
                <ul>
                  <li>Always double check and be extremely careful when investing in alternative coins (altcoins).</li>
                </ul>
                <h3 className="faq__caption">16. Fraudulent ICOs</h3>
                <p>
                  Seduced by the astronomical price rises bitcoin has experienced since its inception, many everyday consumers venture into
                  the world of cryptocurrency looking for the next big thing. After all, if “the next bitcoin” ever actually arrives,
                  getting in at the ground floor could see early-adopters earn a fortune.
                </p>
                <p>
                  And if you want to get in on the ground floor, the easiest option for the average person is to buy coins or tokens in an
                  ICO. If you’re dreaming of getting rich quick from a crypto ICO, be aware that for every ICO success story there are many,
                  many more failures, even if the project isn’t a scam.
                </p>
                <h3 className="faq__caption faq__caption_underline">How to avoid: </h3>
                <ul>
                  <li>
                    Thoroughly research any ICO before buying in. Look at the team behind the project, its white paper, the purpose of the
                    currency, the tech behind it and the specifics of the token sale.
                  </li>
                </ul>
                <hr />
                <h3 className="faq__caption">10 Things You Can Do to Avoid Fraud</h3>
                <p>
                  Millions of people become victims of fraud every year. Scammers combine new technology with old tricks to get people to
                  send money or give out personal information. Here are some practical tips to help you stay a step ahead.
                </p>
                <p>
                  <strong>Spot imposters.</strong> Scammers often pretend to be someone you trust, like a government official, a family
                  member, a charity, or a company you do business with. Don’t send money or give out personal information in response to an
                  unexpected request — whether it comes as a text, a phone call, or an email.{" "}
                </p>
                <p>
                  <strong>Do online searches.</strong> Type a company or product name into your favorite search engine with words like
                  “review,” “complaint” or “scam.” You can even search for phone numbers to see if other people have reported them as scams.
                </p>
                <p>
                  <strong>Don’t believe your caller ID.</strong> Technology makes it easy for scammers to fake caller ID information, so the
                  name and number you see aren’t always real. If someone calls asking for money or personal information, hang up. If you
                  think the caller might be telling the truth, call back to a number you know is genuine.
                </p>
                <p>
                  <strong>Don’t pay upfront for a promise.</strong> Someone might ask you to pay in advance for things like debt relief,
                  credit and loan offers, mortgage assistance, or a job. They might even say you’ve won a prize, but first you have to pay
                  taxes or fees. If you do, they will probably take the money and disappear.{" "}
                </p>
                <p>
                  <strong>Consider how you pay.</strong> Credit cards have significant fraud protection built in, but some payment methods
                  don’t. That’s also true for reloadable cards and gift cards.{" "}
                </p>
                <p>
                  <strong>Talk to someone.</strong> Before you give up your money or personal information, talk to someone you trust. Con
                  artists want you to make decisions in a hurry. They might even threaten you. Slow down, check out the story, do an online
                  search, consult an expert — or just tell a friend.
                </p>
                <p>
                  <strong>Hang up on robocalls.</strong> If you answer the phone and hear a recorded sales pitch, hang up and report. These
                  calls are illegal, and often the products are bogus. Don’t press 1 to speak to a person or to be taken off the list. That
                  could lead to more calls.
                </p>
                <p>
                  <strong>Be skeptical about free trial offers.</strong> Some companies use free trials to sign you up for products and bill
                  you every month until you cancel. Before you agree to a free trial, research the company and read the cancellation policy.
                  And always review your monthly statements for charges you don’t recognize.
                </p>
                <p>
                  <strong>Don’t deposit a check and wire money back.</strong> By law, banks must make funds from deposited checks available
                  within days, but uncovering a fake check can take weeks. If a check you deposit turns out to be a fake, you’re responsible
                  for repaying the bank.
                </p>
                <hr />
                <h3 className="faq__caption">How to Keep Your Personal Information Secure</h3>
                <p>
                  Protecting your personal information can help reduce your risk of identity theft. There are four main ways to do it: know
                  who you share information with; store and dispose of your personal information securely; ask questions before deciding to
                  share your personal information; and maintain appropriate security on your computers and other electronic devices.
                </p>
                <h3 className="faq__caption">Keeping Your Personal Information Secure Offline</h3>
                <ul className="list faq__list">
                  <li>
                    Lock your financial documents and records in a safe place at home, and lock your wallet or purse in a safe place at
                    work.
                  </li>
                  <li>Limit what you carry. When you go out, take only the identification, credit, and debit cards you need.</li>
                  <li>
                    Before you share information at your workplace, a business, your child's school, or a doctor's office, ask why they need
                    it, how they will safeguard it, and the consequences of not sharing.
                  </li>
                  <li>
                    Shred receipts, credit offers, credit applications, insurance forms, physician statements, checks, bank statements,
                    expired charge cards, and similar documents when you don’t need them any longer.
                  </li>
                  <li>Destroy the labels on prescription bottles before you throw them out.</li>
                  <li>
                    Take outgoing mail to post office collection boxes or the post office. Promptly remove mail that arrives in your
                    mailbox.
                  </li>
                  <li>When you order new checks, don’t have them mailed to your home, unless you have a secure mailbox with a lock.</li>
                </ul>
                <h3 className="faq__caption">Keeping Your Personal Information Secure Online</h3>
                <ul className="list faq__list">
                  <li>Know who you share your information with. Store and dispose of your personal information securely.</li>
                  <li>Be Alert to Impersonators.</li>
                  <li>
                    Make sure you know who is getting your personal or financial information. Don’t give out personal information on the
                    phone, through the mail or over the Internet unless you’ve initiated the contact or know who you’re dealing with. If a
                    company that claims to have an account with you sends email asking for personal information, don’t click on links in the
                    email. Instead, type the company name into your web browser, go to their site, and contact them through customer
                    service. Or, call the customer service number listed on your account statement. Ask whether the company really sent a
                    request.
                  </li>
                  <li>Safely Dispose of Personal Information.</li>
                  <li>
                    Before you dispose of a computer, get rid of all the personal information it stores. Use a wipe utility program to
                    overwrite the entire hard drive.
                  </li>
                  <li>
                    Before you dispose of a mobile device, check your owner’s manual, the service provider’s website, or the device
                    manufacturer’s website for information on how to delete information permanently, and how to save or transfer information
                    to a new device. Remove a SIM card from a mobile device. Remove the phone book, lists of calls made and received,
                    voicemails, messages sent and received, organizer folders, web search history, and photos.
                  </li>
                  <li>Encrypt your data.</li>
                  <li>
                    Keep your browser secure. To guard your online transactions, use encryption software that scrambles information you send
                    over the Internet. A “lock” icon on the status bar of your Internet browser means your information will be safe when
                    it’s transmitted. Look for the lock before you send personal or financial information online.
                  </li>
                  <li>Keep passwords private.</li>
                  <li>
                    Use strong passwords with your laptop, credit, bank, and other accounts. Be creative: think of a special phrase and use
                    the first letter of each word as your password. Substitute numbers for some words or letters.
                  </li>
                  <li>Don’t Overshare on Social Networking Sites.</li>
                  <li>
                    If you post too much information about yourself, an identity thief can find information about your life, use it to
                    answer ‘challenge’ questions on your accounts, and get access to your money and personal information. Consider limiting
                    access to your networking page to a small group of people. Never post your full name, address, phone number, or account
                    numbers in publicly accessible sites.
                  </li>
                </ul>
                <h3 className="faq__caption">Keeping Your Devices Secure</h3>
                <ul className="list faq__list">
                  <li>Use security software.</li>
                  <li>
                    Install anti-virus software, anti-spyware software, and a firewall. Set your preference to update these protections
                    often. Protect against intrusions and infections that can compromise your computer files or passwords by installing
                    security patches for your operating system and other software programs.
                  </li>
                  <li>Avoid phishing emails.</li>
                  <li>
                    Don’t open files, click on links, or download programs sent by strangers. Opening a file from someone you don’t know
                    could expose your system to a computer virus or spyware that captures your passwords or other information you type.
                  </li>
                  <li>Be wise about wi-fi.</li>
                  <li>
                    Before you send personal information over your laptop or smartphone on a public wireless network in a coffee shop,
                    library, airport, hotel, or other public place, see if your information will be protected. If you use an encrypted
                    website, it protects only the information you send to and from that site. If you use a secure wireless network, all the
                    information you send on that network is protected.
                  </li>
                  <li>Lock up your laptop.</li>
                  <li>
                    Keep financial information on your laptop only when necessary. Don’t use an automatic login feature that saves your user
                    name and password, and always log off when you’re finished. That way, if your laptop is stolen, it will be harder for a
                    thief to get at your personal information.
                  </li>
                  <li>Read privacy policies.</li>
                  <li>
                    Yes, they can be long and complex, but they tell you how the site maintains accuracy, access, security, and control of
                    the personal information it collects; how it uses the information, and whether it provides information to third parties.
                    If you don’t see or understand a site’s privacy policy, consider doing business elsewhere.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer isDark={false} />
    </>
  );
};
